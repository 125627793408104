<template>
  <Observer name="fade-up" :class="classObs" rootMargin="100px" @enter="enter">
    <div v-if="isFromCustomerService" class="product text-center -no-hover">
      <Observer name="lazy-image" :className="'product-item-image watch no-hover-img'" :rootMargin="'0px'">
        <img :src="fullProduct.image + '&width=800'" :alt="fullProduct.sku || ' '" loading="lazy" />
      </Observer>
    </div>
    <router-link v-else class="product tile-padding text-center" :class="{ '-cover': fullProduct.imageHover, 'swap-images': swapImages }" :dataSku="fullProduct.sku" :to="fullProduct.routeParam ? `${localizedUrl}/product/${fullProduct.routeParam}` : `${localizedUrl}/product/${fullProduct.url_key}`" :aria-label="discoverLabel" @click.native="callGTM('eeListClick')">
      <div v-if="swapImages" class="product-item-image-wrapper">
        <lazy-image class-name="product-image-primary" ref="pictureSwap" :item="pictureSwap" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
        <lazy-image v-if="imageUrl" class-name="product-image-secondary watch" ref="pictureSwapHover" :item="pictureSwapHover" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
      </div>
      <template v-else>
        <lazy-image v-if="imageUrl" :class-name="imageUrlHover ? 'product-item-image watch' : 'product-item-image watch no-hover-img'" ref="picture" :item="picture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
        <lazy-image v-if="imageUrlHover" class-name="product-item-hover-image cover" ref="pictureHover" :item="pictureHover" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
      </template>
      <div class="tile-information">
        <span class="text -small" aria-hidden="true" v-if="fullProduct.year_of_launch" v-text="fullProduct.year_of_launch"></span>
        <h2 class="title -small" aria-hidden="true" v-if="inPLP && fullProduct.name" v-text="fullProduct.name"></h2>
        <h3 class="title -small" aria-hidden="true" v-if="!inPLP && fullProduct.name" v-text="fullProduct.name"></h3>
        <span class="text -small" aria-hidden="true" v-if="getProductPrice(fullProduct)">{{ getProductPrice(fullProduct) | currency }}</span>
        <span class="text -small" aria-hidden="true" v-else-if="strapBasePrice(fullProduct)">{{ strapBasePrice(fullProduct) | currency }}</span>
        <span class="text -small" aria-hidden="true" v-else>&nbsp;</span>
        <div class="text -small -fake" aria-hidden="true" v-if="!fullProduct.price && fullProduct.is_icon"></div>
        <div class="btn -small" aria-hidden="true" v-if="isShopNow">{{ $t('Shop now') }}</div>
        <div class="btn -small" aria-hidden="true" v-if="isPreorder">{{ $t('Preorder') }}</div>
        <div class="btn -small" aria-hidden="true" v-if="isDiscover">{{ $t('Discover') }}</div>
        <div class="badges-wrapper" aria-hidden="true">
          <span class="text -small -sold -upper" aria-hidden="true" v-if="!hideLabel && isProductSoldOut && fullProduct.is_icon">{{ $t('Sold') }}</span>
          <span class="text -small -sold -upper" aria-hidden="true" v-if="!hideLabel && isProductSoldOut && !fullProduct.is_icon && fullProduct.isShoppable">{{ $t('Sold out') }}</span>
          <span class="text -small -boutique -upper" aria-hidden="true" v-if="isEditionType">{{ isEditionType }}</span>
          <!-- span class="text -small -boutique" aria-hidden="true" v-if="isBoutiqueEdition">{{ $t('Boutique Edition') }}</span -->
          <!-- span class="text -small -exclusive" aria-hidden="true" v-if="isOnlyOnline">{{ $t('Online exclusive') }}</span -->
          <span class="text -small -limited -upper" aria-hidden="true" v-if="!isProductSoldOut && isLimitedEdition">{{ $t('Limited edition') }}</span>
          <span class="text -small -special -upper" aria-hidden="true" v-else-if="isSpecialEdition">{{ $t('Special edition') }}</span>
        </div>
      </div>
    </router-link>
    <button type="button" class="close" v-if="close" @click.stop.prevent="removeFromWishlist(fullProduct.sku)" :aria-label="wishlistLabel($t('Remove to wishlist'))">
      <i class="icon-close"></i>
    </button>
    <button v-if="accountActivated && product.exist_in_catalog && listName !== 'Straps'" type="button" @click.stop.prevent="determineWishlist" :class="isInWishlist ? 'fav active' : 'fav'" :aria-label="wishlistLabel(isInWishlist ? $t('Remove to wishlist') : $t('Add to wishlist'))">
      <i :class="isInWishlist ? 'icon-fav-full' : 'icon-fav'" v-if="!close"></i>
      <span
        class="icon-anim"
        :style="{
          '--translateX': generateCoordinate(),
          '--translateY': generateCoordinate(),
        }"
      >
        <i class="icon-fav-full"></i>
      </span>
      <span
        class="icon-anim"
        :style="{
          '--translateX': generateCoordinate(),
          '--translateY': generateCoordinate(),
        }"
      >
        <i class="icon-fav-full"></i>
      </span>
    </button>
  </Observer>
</template>

<script>
import { ReadyHook } from 'vue-asyncready'
import ProductItemData from '../utilities/product-item-data'
import LazyImage from './utils/LazyImage'
import { getProductPrice } from '../utilities'

export default {
  props: ['product', 'close', 'classObs', 'listPosition', 'listName', 'listType', 'isFromCustomerService', 'inPLP'],
  name: 'ProductItem',
  mixins: [ReadyHook, ProductItemData],
  components: {
    LazyImage,
  },
  methods: {
    getProductPrice: getProductPrice
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.obs {
  height: 100%;
}

.product {
  position: relative;
  background: var(--zenith-component-product-item-background);
  // min-height: 50rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  backface-visibility: hidden;

  &.tile-padding {
    padding: 4rem 2rem 3rem;
    @include bp(max md) {
      padding: 2rem 1rem 2rem;
    }
    @include bp(max sm) {
      padding: 2rem 1rem 2rem;
    }

    &.swap-images {
      padding: 0 0 3rem;
      @include bp(max md) {
        padding: 0 0 2rem;
      }
    }
  }

  &.swap-images {
    justify-content: space-between;

    .product-item-image-wrapper {
      position: relative;
      overflow: hidden;
      margin-bottom: 1rem;
    }

    .product-image-secondary {
      opacity: 0;
      padding: 4rem 2rem 0;
      @include bp(max md) {
        padding: 2rem 1rem 0;
      }
    }

    .product-image-primary {
      position: absolute;
      overflow: hidden;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      opacity: 1;
      transform: translate(-50%, -50%);
      transition: all 0s $ExpoEaseOut;
      pointer-events: none;

      /deep/ img {
        width: 100%;
        height: auto;
        object-fit: cover;
        max-width: none;
        transition: all 1s $ExpoEaseOut;
      }
    }

    &.-cover {
      &:hover,
      &:hover:focus,
      &:active {
        .product-image-primary {
          opacity: 0;
        }
        .product-image-secondary {
          opacity: 1;
        }
      }
    }

    .tile-information {
      padding: 0 2rem;
      @include bp(max md) {
        padding: 0 1rem;
      }
    }
  }

  .link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
  }

  .text.-fake {
    display: block;
    min-height: 1.68rem;
    margin-top: 1rem;
  }

  span,
  h2,
  h3 {
    position: relative;
    z-index: 20;
    margin-bottom: 0;
    display: block;
    color: var(--zenith-component-product-item-price);
    text-transform: uppercase;
    pointer-events: none;
    transition: all 0.4s ease-in-out;
    &:first-child {
      margin-top: auto;
    }

    &.title {
      color: var(--zenith-component-product-item-title);
      & ~ span {
        margin-top: 1rem;
      }
    }

    &.text {
      & + .title {
        margin-top: 1rem;
      }
    }
  }

  .badges-wrapper {
    margin-top: 1rem;
    margin-bottom: -1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    @include bp(md) {
      margin-bottom: -2rem;
    }

    & > span {
      flex: 1 1 calc(50% - 0.5rem);
      max-width: calc(50% - 0.5rem);
      background-color: var(--zenith-component-product-item-badge);
      border-radius: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: em(8);
      text-align: center;
      padding: 0.1rem;
      margin-top: 0;
      word-break: keep-all;

      @include bp(lg) {
        font-size: em(10);
      }
    }
  }

  .watch {
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;

    /deep/ img {
      width: 100%;
      max-width: 25rem;
      transition: all 0.6s $ExpoEaseOut;
    }
  }

  .cover {
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: all 0s $ExpoEaseOut;
    pointer-events: none;

    /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.1);
      transition: all 1s $ExpoEaseOut;
    }
  }

  .btn {
    position: absolute;
    z-index: 30;
    bottom: 2rem;
    left: 50%;
    opacity: 0;
    width: calc(100% - 4rem);
    pointer-events: none;
    transform: translate(-50%, 1rem);

    @include bp(max md) {
      bottom: 2rem;
    }
  }

  &:not(.-no-hover) {
    &:hover,
    &:active {
      background: var(--zenith-white);
      overflow: visible;

      @include bp(md) {
        .watch {
          /deep/ img {
            transform: scale(1.05);
          }
          &.no-hover-img {
            /deep/ img {
              transform: scale(1.05);
            }
          }
        }
      }

      .cover {
        opacity: 1;
        /deep/ img {
          transform: scale(1);
        }
      }
      & + .close {
        opacity: 1;
        & + .fav {
          opacity: 0;
        }
      }
      .btn {
        opacity: 1;
        pointer-events: none;
        transform: translate(-50%, 0);
      }
      .title,
      .text {
        opacity: 0;
      }
    }

    &:focus {
      @include bp(md) {
        .watch {
          /deep/ img {
            transform: scale(1.05);
          }
          &.no-hover-img {
            /deep/ img {
              transform: scale(1.05);
            }
          }
        }
      }
    }
  }

  &.-cover {
    &:hover,
    &:hover:focus,
    &:active {
      & + .fav,
      & + .fav-full,
      & + .close {
        .icon-fav {
          color: var(--zenith-component-title);
          &-full {
            color: var(--zenith-component-title);
          }
        }
        &:hover {
          .icon-fav {
            color: var(--zenith-component-product-item-heart-hover-with-cover);
            &-full {
              color: var(--zenith-component-product-item-heart-hover-with-cover);
            }
          }
        }

        span {
          &.icon-anim {
            i {
              color: var(--zenith-component-product-item-heart-anim);
            }
          }
        }
      }
      .btn {
        pointer-events: none;
      }
    }
  }
}

.fav,
.close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 40;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  transition: all 0.2s ease-in-out;

  @include bp(max md) {
    top: 0.7rem;
    right: 0.7rem;
  }
}

.close {
  opacity: 0;

  &:hover,
  &:focus,
  &:active {
    transform: scale(1.1);
  }

  i {
    display: block;
    color: var(--zenith-component-product-item-like);
  }
}

.fav {
  i {
    display: block;
    &.icon-fav {
      color: var(--zenith-component-product-item-like);

      &-full {
        color: var(--zenith-component-product-item-like);
      }
    }
  }

  span {
    &.icon-anim {
      position: absolute;
      top: 2%;
      left: 8%;
      opacity: 0;
      pointer-events: none;
      display: inline-block;
      font-size: 12px;
      z-index: -1;

      i {
        color: var(--zenith-component-product-item-heart-anim);
      }
    }
  }

  &:hover,
  &:focus {
    i {
      transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      transform: scale(1.2);
      &.icon-fav {
        color: var(--zenith-component-product-item-heart-hover);
        &:before {
          content: '\e93d';
        }

        &-full {
          color: var(--zenith-component-product-item-heart-hover);
        }
      }
    }
    span {
      &.icon-anim {
        i {
          color: var(--zenith-component-product-item-heart-anim);
        }
      }
    }
  }

  &.active {
    i {
      display: block;
      animation: beat cubic-bezier(0.04, 0.4, 0.5, 0.95) 1.2s forwards 1;
    }
    span {
      &.icon-anim {
        animation: floatAway ease-out 2s forwards 1;
      }
    }
  }
}
</style>
